import React from "react";
import App from "next/app";
import { UserProvider } from "../context/UserContext";
import { CartProvider } from "../context/CartContext";
import { ComparisonProvider } from "../context/ComparisonContext";
import { InteriorDesignerProvider } from "../context/InteriorDesignerContext";
import { WishlistProvider } from "../context/WishlistContext";
import { RecentlyViewedProvider } from "../context/RecentlyViewedContext";
import {
  CommonDataProvider,
  useCommonData,
} from "../context/CommonDataContext";
import { CookieModal } from "../modals/CookieModal";
import { AuthModal } from "../modals/AuthModal";
import { ModalProvider } from "../context/ModalContext";
import { ResponsiveProvider } from "../context/ResponsiveContext";
import { StickyPanel } from "../components/StickyPanel";
import { InteriorDesignerModal } from "../modals/InteriorDesignerModal";
import { getAffirmCredentials } from "../helpers/env";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import NextNProgress from "nextjs-progressbar";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "./global.css";
import "./normalize.css";
import "./animation.css";
import "./vendor.css";
import mixpanel from "mixpanel-browser";
import SubscribeModal from "../components/SubscribeModal";
import EventsTracker from "../helpers/fb";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://c40f720ca8d147838c956413e8658339@o520630.ingest.sentry.io/1836529",
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,
  });
}

function getAffirmScript(affirmCredentials) {
  return `
    _affirm_config = {
      public_api_key:  "${affirmCredentials.PUBLIC_API_KEY}",
      script:          "${affirmCredentials.JS_URL}"
    };
    (function(l,g,m,e,a,f,b){var d,c=l[m]||{},h=document.createElement(f),n=document.getElementsByTagName(f)[0],k=function(a,b,c){return function(){a[b]._.push([c,arguments])}};c[e]=k(c,e,"set");d=c[e];c[a]={};c[a]._=[];d._=[];c[a][b]=k(c,a,b);a=0;for(b="set add save post open empty reset on off trigger ready setProduct".split(" ");a<b.length;a++)d[b[a]]=k(c,e,b[a]);a=0;for(b=["get","token","url","items"];a<b.length;a++)d[b[a]]=function(){};h.async=!0;h.src=g[f];n.parentNode.insertBefore(h,n);delete g[f];d(g);l[m]=c})(window,_affirm_config,"affirm","checkout","ui","script","ready");
    // Use your live public API Key and https://cdn1.affirm.com/js/v2/affirm.js script to point to Affirm production environment.
  `;
}

function CheckoutScripts() {
  const { settings } = useCommonData();
  const affirmCredentials = getAffirmCredentials(settings.checkout_sandbox);

  return (
    <>
      <script src="https://js.stripe.com/v3/"></script>
      <script
        dangerouslySetInnerHTML={{
          __html: getAffirmScript(affirmCredentials),
        }}
      />
    </>
  );
}

class MyApp extends App {
  // Only uncomment this method if you have blocking data requirements for
  // every single page in your application. This disables the ability to
  // perform automatic static optimization, causing every page in your app to
  // be server-side rendered.
  //
  // static async getInitialProps(appContext) {
  //   // calls page's `getInitialProps` and fills `appProps.pageProps`
  //   const appProps = await App.getInitialProps(appContext);
  //
  //   return { ...appProps }
  // }

  checkOlark() {
    if (typeof window.olark === "undefined") return;

    window.olark("api.box.hide");
  }

  componentDidMount() {
    this.checkOlark();
    mixpanel.init("ec7c0fc611fa7409d54ae72664de20d7", { batch_requests: true });
    mixpanel.opt_in_tracking();
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <CommonDataProvider data={pageProps.commonData}>
        <ResponsiveProvider>
          <UserProvider>
            <ModalProvider>
              <CartProvider>
                <WishlistProvider>
                  <RecentlyViewedProvider>
                    <ComparisonProvider>
                      <InteriorDesignerProvider>
                        <NextNProgress
                          color="#a3a3a3"
                          startPosition={0}
                          stopDelayMs={200}
                          height={3}
                          options={{ easing: "ease", speed: 800 }}
                        />
                        <Component {...pageProps} />
                        <StickyPanel />
                        <InteriorDesignerModal />
                        <CheckoutScripts />
                      </InteriorDesignerProvider>
                    </ComparisonProvider>
                  </RecentlyViewedProvider>
                </WishlistProvider>
              </CartProvider>
              <AuthModal />
              <CookieModal />
              <SubscribeModal />
            </ModalProvider>
          </UserProvider>
        </ResponsiveProvider>
      </CommonDataProvider>
    );
  }
}

export default MyApp;
