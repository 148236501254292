export const storage = {
  /** saveDataToStorage
   * @param key (String): name of the localstorage item to be saved
   * @param data (Object): contents of the data to be saved
   */
  saveDataToStorage: (key: string, data: Record<string, unknown>) => {
    window.localStorage.setItem(key, JSON.stringify(data));
  },

  /**
   * getDataFromStorage
   * @param key (String): name of the key to be returned from localStorage
   */
  getDataFromStorage: (key: string): any => {
    const storageData = window.localStorage.getItem(key);
    if (storageData) {
      const data = JSON.parse(storageData);

      return data;
    }

    return {};
  },

  /** updateDataInStorage
   * @param key (String): name of the localstorage item to be updated
   * @param data (Object): contents of the data to be updated
   */
  updateDataInStorage: (key: string, data: Record<string, unknown>) => {
    const existingData = storage.getDataFromStorage(key);
    const storageData = { ...existingData, ...data };

    Object.keys(storageData).forEach((item) => {
      if (typeof storageData[item] === "string" && !storageData[item]) {
        delete storageData[item];
      }
    });
    storage.saveDataToStorage(key, { ...storageData });
  },

  /**
   * delete
   * @param key (String): name of the instance to be removed
   */
  delete: (key: string) => {
    window.localStorage.removeItem(key);
  },
};
