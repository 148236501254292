import React from "react";
import { BaseModal } from "./BaseModal";
import { css } from "styled-components";
import { Column, Spacing } from "../helpers/layout";
import { NotLoggedInForm } from "../components/AccountForm";
import { Colors } from "../branding";
import { useModal } from "../context/ModalContext";
import { useResponsive } from "../context/ResponsiveContext";

export function AuthModal() {
  const { modal, modalCallback, hideModal } = useModal();
  const { isMobile } = useResponsive();

  if (!modal || modal.type !== "login") return null;

  return (
    <BaseModal
      visible={true}
      onClose={() => {
        hideModal();
      }}
    >
      <div
        css={css`
          padding: ${isMobile ? Spacing.l : Spacing.xxl};
          max-width: 660px;
          width: 100vw;
          box-sizing: border-box;
          background: ${Colors.white};
          color: ${Colors.black};
        `}
      >
        <Column gutter={Spacing.m}>
          <div>
            <NotLoggedInForm
              onSignIn={modalCallback || undefined}
              type={modal ? modal.options.type : undefined}
            />
          </div>
        </Column>
      </div>
    </BaseModal>
  );
}
