import React, { useEffect, useState } from 'react';
import { css } from "styled-components";

import { COOKIE_LEVEL } from "../helpers/zIndex";
import { Spacing } from "../helpers/layout";
import { Colors, SMALL_MOBILE, TABLET } from "../branding";
import { Button } from '../components/Button';
import { Link } from '../components/Link';
import { storage } from '../helpers/storage'
import { Wrapper } from '../components/Wrapper';
import { useUserState } from '../context/UserContext';
import { saveCookieStatus } from '../actions/saveCookiesStatus';

const COOKIE_KEY = 'drewCookie'

export function CookieModal() {
  const [isVisible, toggleModal] = useState(true);
  const [storageData, setStorageData] = useState<{date?: Date, accepted?: boolean}>({})
  const user = useUserState()

  useEffect(() => {
    const consentData: {date: Date;} = storage.getDataFromStorage(COOKIE_KEY);
    if (Object.keys(consentData).length) {
      setStorageData(consentData);
      toggleModal(!Object.keys(consentData).length);
    }
  }, []);

  useEffect(() => {
    if (
      user.isAuthenticated &&
      storageData?.date &&
      user.userprofile?.user.username
    ) {
      const d = new Date(storageData?.date).toISOString();
      saveCookieStatus({
        timestamp: d,
        email: user.userprofile?.user.username || "",
      });
    }
  }, [user.isAuthenticated, user.userprofile]);



  return (
    <div
      css={css`
        background: ${Colors.very_light_grey};
        width: 100%;
        padding: ${Spacing.m} 0;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: ${COOKIE_LEVEL};
        box-sizing: border-box;
        display: ${isVisible ? "block" : "none"};
      `}
    >
      <Wrapper
        css={css`
          display: flex;
          text-align: left;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          @media (max-width: ${TABLET}px) {
            justify-content: space-between;
            align-items: start;
          }
        `}
      >
        <p
          css={css`
            font-size: 12px;
            margin: 0 10px 0 0;
          `}
        >
          Your use of this website is subject to our{' '}
          <Link href="/terms-of-use" underlined target="_blank">
            Terms of Use
          </Link>,{' '}
          <Link href="/privacy-policy" underlined target="_blank">
            Privacy Policy
          </Link> and{' '}
          <Link href="/products-and-services" underlined target="_blank">
            Terms of Sale
          </Link>
        </p>
        <div
          css={css`
            min-width: 100px;

            @media (max-width: ${SMALL_MOBILE}px) {
              min-width: 84px;
            }
          `}
        >
          <Button
            style="black-border"
            type="button"
            onClick={() => {
              storage.saveDataToStorage(COOKIE_KEY, {
                accepted: true,
                date: Date.now(),
              });
              toggleModal(false);
            }}
            label="Accept"
          />
        </div>
      </Wrapper>
    </div>
  );
}
