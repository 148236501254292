import { authorised } from "../request";

export async function saveCookieStatus(data: { timestamp: string, email: string }) {
  const API = authorised()

  API.request({
    method: "POST",
    url: '/api/frontend/update-cookies/',
    data
  })
}
