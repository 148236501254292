import React from "react";
import { FullscreenModal } from "./FullscreenModal";
import { useInteriorDesigner } from "../context/InteriorDesignerContext";
import { InteriorDesigner } from "../components/InteriorDesigner";

export function InteriorDesignerModal() {
  const { opened, closeInteriorDesigner } = useInteriorDesigner();

  if (!opened) return null;

  return (
    <React.Fragment>
      <FullscreenModal
        noPadding
        visible={opened}
        onClose={() => {
          closeInteriorDesigner();
        }}
      >
        <InteriorDesigner
          onClose={() => {
            closeInteriorDesigner();
          }}
        />
      </FullscreenModal>
    </React.Fragment>
  );
}
