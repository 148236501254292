import React, { useState, useEffect } from "react";
import { css } from "styled-components";
import ArrowUp from "../icons/ArrowUp";
import { Colors } from "../branding";
import throttle from "lodash/throttle";
import { Spacing } from "../helpers/layout";

export function ScrollTop() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const el = document.documentElement;
      const clientHeight = el.clientHeight;
      const scrollTop = document.body.scrollTop || el.scrollTop;
      const reachedPoint = scrollTop > clientHeight;

      if (reachedPoint) {
        setShow(true);
      } else {
        setShow(false);
      }
    }, 200);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      css={css`
        padding: 0 ${Spacing.m};
        margin-right: ${show ? "0px" : "-57px"};
        transition: margin 300ms;
        cursor: pointer;

        svg {
          fill: ${Colors.white};
        }
      `}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      <ArrowUp />
    </div>
  );
}
