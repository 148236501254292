import React, { useState, useEffect } from "react";
import { BaseModal } from "../modals/BaseModal";
import styled, { css } from "styled-components";
import { Spacing, Column } from "../helpers/layout";
import { Colors, TABLET } from "../branding";
import { useUserState } from "../context/UserContext";
import { ItalicTitle } from "../typography";
import { SendgridForm } from "../forms/SubscribeForm";
import { Button } from "./Button";
import { Message } from "./Message";
import Link from "next/link";

const LOCAL_STORAGE_KEY = "subscribe-modal-closed";
const POPUP_APPEAR_TIMEOUT_MS = 10_000;
const BENEFITS = [
  "Receive 10% off books & special edition prints",
  "Access print and frame pricing",
  "Receive exclusive invites to in-person events",
  "Get first access to join Drew on once-in-a-lifetime travel opportunities",
];

const SubscribeModal = () => {
  const [open, setOpen] = useState(false);
  const { isAuthenticated } = useUserState();

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem(LOCAL_STORAGE_KEY, "true");
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (!isAuthenticated) {
      timeout = setTimeout(() => {
        const wasSeenBefore = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (!wasSeenBefore) {
          setOpen(true);
        }
      }, POPUP_APPEAR_TIMEOUT_MS);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isAuthenticated]);

  return (
    <BaseModal visible={open} onClose={handleClose}>
      <div
        css={`
          padding-inline: 20px;
        `}
      >
        <div
          css={css`
            max-width: 660px;
            width: calc(100vw - 40px);
            box-sizing: border-box;
            background: ${Colors.white};
            color: ${Colors.black};

            padding: ${Spacing.l};
            @media (min-width: ${TABLET}px) {
              padding: ${Spacing.xxl};
            }
          `}
        >
          <ItalicTitle
            css={`
              text-align: center;
            `}
          >
            Join Our Community
          </ItalicTitle>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-top: 30px;
              text-align: center;
              font-size: 13px;
            `}
          >
            <span>
              We promise only to send you the storytelling, ideas, and studio
              news that matters.
            </span>
            <span
              css={css`
                margin-top: 10px;
              `}
            >
              Additionally, when you sign up, you’ll:
            </span>
            <ul
              css={`
                text-align: left;
                padding-left: 20px;
              `}
            >
              {BENEFITS.map((benefit, i) => (
                <li
                  key={i}
                  css={`
                    font-size: 12px;
                  `}
                >
                  <span>{benefit}</span>
                </li>
              ))}
            </ul>
          </div>

          <SendgridForm>
            {({
              name,
              email,
              errorMessage,
              setEmail,
              setName,
              successMessage,
              loading,
            }) => (
              <Column gutter={Spacing.none} justify="center" align="center">
                <Input
                  type="text"
                  value={name}
                  name="FNAME"
                  placeholder="First Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <Input
                  type="email"
                  value={email}
                  name="EMAIL"
                  placeholder="Email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  css={css`
                    border-bottom-color: ${errorMessage
                      ? `${Colors.error}!important`
                      : undefined};
                  `}
                />
                <div
                  css={css`
                    flex: 0 0 auto;
                    display: flex;
                    flex-direction: column;
                    margin-top: 10px;
                  `}
                >
                  <Button
                    type="submit"
                    label="Subscribe"
                    isSubmitting={loading}
                  />
                </div>
                {errorMessage && (
                  <div
                    css={css`
                      margin-top: ${Spacing.m};
                    `}
                  >
                    <Message text={errorMessage} />
                  </div>
                )}
                {successMessage && (
                  <div
                    css={css`
                      margin-top: ${Spacing.m};
                    `}
                  >
                    <Message text={successMessage} success />
                  </div>
                )}
              </Column>
            )}
          </SendgridForm>

          <div
            css={css`
              display: flex;
              flex-direction: column;
              text-align: center;
              gap: 20px;
              margin-top: 30px;
            `}
          >
            <span>
              Already Have An Account?{" "}
              <span onClick={handleClose}>
                <Link href="/login">
                  <a
                    css={css`
                      color: ${Colors.black};
                      cursor: pointer;
                    `}
                  >
                    <u>Log In Here</u>
                  </a>
                </Link>
              </span>
            </span>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

const Input = styled.input`
  padding-inline: 10px;
  border: none;
  background: none;
  height: 45px;
  width: 100%;
  font-size: 12px;
  line-height: 45px;
  box-sizing: border-box;
  letter-spacing: 0.15em;
  outline: none;
  color: ${Colors.black};
  flex: 1 0 10%;
  border-bottom: 1px solid;
  border-bottom-color: ${Colors.dark_gray};
  text-transform: none;
  transition: border-color 0.2s linear;
  margin-bottom: 10px;

  &:focus {
    border-bottom-color: ${Colors.black};
  }

  ::placeholder {
    text-transform: uppercase;
    font-size: 11px;
    color: ${Colors.dark_gray};
  }
`;

export default SubscribeModal;
