import React from "react";
import { css } from "styled-components";
import { Spacing } from "../helpers/layout";
import { ScrollTop } from "./ScrollTop";
import { Colors } from "../branding";
import { MODAL_LEVEL } from "../helpers/zIndex";

export function StickyPanel() {
  return (
    <div
      className="scroll-top"
      css={css`
        position: fixed;
        bottom: ${Spacing.m};
        right: ${Spacing.m};
        background: ${Colors.black};
        padding: ${Spacing.m} 0;
        overflow: hidden;
        box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 0.4);
        z-index: ${MODAL_LEVEL - 1};
      `}
    >
      <ScrollTop />
    </div>
  );
}
