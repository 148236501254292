const AFFIRM_PRODUCTION = {
  PUBLIC_API_KEY: "YAFNU8W88T3YMBT0",
  API_URL: "https://api.affirm.com/api/v2/",
  API_BASE_URL: "https://api.affirm.com",
  JS_URL: "https://cdn1.affirm.com/js/v2/affirm.js"
};

const AFFIRM_SANDBOX = {
  PUBLIC_API_KEY: "SZNI7680B9V4ABJV",
  API_URL: "https://sandbox.affirm.com/api/v2/",
  API_BASE_URL: "https://sandbox.affirm.com",
  JS_URL: "https://cdn1-sandbox.affirm.com/js/v2/affirm.js"
};

const STRIPE_PRODUCTION = {
  API_KEY: "pk_live_gymQtJyd30MeiSCa29GchuVt"
};

const STRIPE_SANDBOX = {
  API_KEY: "pk_test_3FIjUxjF2O3VTG3vQ41RW8ZL"
};

export function getAffirmCredentials(sandbox: boolean) {
  return sandbox ? AFFIRM_SANDBOX : AFFIRM_PRODUCTION;
}

export function getStripeCredentials(sandbox: boolean) {
  return sandbox ? STRIPE_SANDBOX : STRIPE_PRODUCTION;
}
